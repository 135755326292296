import React from "react"

import { Container, Row, Col } from "reactstrap"
import Link from "../components/link"
import Button from "../components/btn"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaGithub, FaBolt, FaHome, FaWrench } from "react-icons/fa"
import Form from "../components/form"
import Slider from "../components/slider"
import Box from "../components/box"
import Hr from "../components/hr"
import Benefits from "../components/benefits"
import styled from "styled-components"
import HomeFeatures from "../components/homeFeatures"
import Testimonials from '../components/testimonials'
import { StaticImage } from 'gatsby-plugin-image'
import PageTitle from "../components/page-title"

let StyledBackground = styled.div`
  background: #000090;
`

let Partner = ({ title}) => (
  <Col xs={12} md={3} className="mb-3">
    <StyledBackground>
      
      <div style="text-align: center; font-size: 34px; line-height: 130px;">
        <font color="#ffffff" style="font-size: 22px;">{title}</font></div>
    </StyledBackground>
  </Col>
)

let Index = () => (
  <Layout>
<PageTitle title="合作伙伴" />
   
    <Container className="py-5">
      <Row>
        <Partner title="中国铁路北京局" />
        <Partner title="中国铁路太原局" />
        <Partner title="中国铁路乌鲁木齐局"/>
        <Partner title="中国铁路郑州局" />

        <Partner title="中国铁路南宁局" />
        <Partner title="中国铁路西安局" />
        <Partner title="广铁集团"/>
        <Partner title="青藏铁路" />
      </Row>
    </Container>
    

    

  </Layout>
)

export default Index
